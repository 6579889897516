<template lang="pug">
v-app-bar(app, color="primary", dark, fixed, flat)
  .d-flex.align-center
    v-img.mr-2.svg-primary(
      alt="Logo",
      contain,
      src="@/assets/point-pioneers-white.svg",
      transition="scale-transition",
      height="64",
      width="250",
      v-on:click="navigate('')",
      style="cursor: pointer"
    )

  v-spacer
  v-select.select.mt-5(
    label="Language",
    v-model="$i18n.locale",
    v-bind:items="languages",
    prepend-icon="mdi-web"
  )

  v-menu(:open.sync="menuOpen", offset-y)
    template(v-slot:activator="{ on }")
      v-btn(v-on="on", color="white", text) {{ $t("appBar.consultants") }}
    v-list(color="secondary")
      v-list-item(v-on:click="navigate('hans-steinbacher')") Hans Steinbacher
  //v-btn(href="mailto:kontakt@point-pioneers.net", target="_blank", text)
    span.mr-2 Kontaktieren Sie uns
</template>
<script>
export default {
  data () {
    return {
      menuOpen: false,
      languages: [
        {
          text: "English",
          value: "en"
        },
        {
          text: "Deutsch",
          value: "de"
        }
      ]
    };
  },
  methods: {
    handleButtonClick () {
      this.menuOpen = !this.menuOpen;
    },
    navigate (item) {
      this.$router.push(`/${item}`);
    },
  },
};
</script>
<style scoped>
.select {
  max-width: 140px;
}
</style>
