import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#3976a2',
                secondary: '#e9e6dd',
                accent: '#5bb246'
            },
        },
        options: {
            customProperties: true
        }
    },
});
