









import { Component, Vue } from "vue-property-decorator";
import AppBar from "@/AppBar.vue";
import Footer from "@/Footer.vue";

@Component({
  components: {
    AppBar,
    Footer,
  },
})
export default class App extends Vue {}
