import Vue from "vue";
import Router from "vue-router";

Vue.use( Router );

const router = new Router( {
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "Point Pioneers",
            component: () => import(/* webpackChunkName: "Welcome" */ "./Views/Scrum.vue" )
        },
        {
            path: "/impressum",
            name: "Impressum",
            component: () => import(/* webpackChunkName: "Impressum" */ "./Views/Impressum.vue" )
        },
        {
            path: "/privacy",
            name: "Privacy",
            component: () => import(/* webpackChunkName: "Impressum" */ "./Views/Privacy.vue" )
        },
        {
            path: "/qr",
            name: "Point Pioneers",
            component: () => import(/* webpackChunkName: "Welcome" */ "./Views/Scrum.vue" )
        },        
        {
            path: "/hans-steinbacher",
            name: "Hans Steinbacher - Point Pioneers",
            component: () => import(/* webpackChunkName: "Welcome" */ "./Views/CV.vue" )
        },
        {
            path: "*",
            name: "404",
            component: () => import(/* webpackChunkName: "404" */ "./Views/404.vue" )
        },
    ],
} );

export default router;
