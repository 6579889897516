
















import { Component, Vue } from "vue-property-decorator";

@Component
export default class Footer extends Vue {
  buttons = [
    {
      label: "Home",
      route: "/",
    },
    {
      label: "footer.contact",
      target: "_blank",
      href: "mailto:kontakt@point-pioneers.net",
    },
    {
      label: "footer.imprint",
      route: "/impressum/",
    },
    {
      label: "footer.privacy",
      route: "/privacy/",
    },
  ];

  navigate(route: string | undefined) {
    if (route !== undefined) {
      this.$router.push(route).catch(() => {});
    }
  }
}
